import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default function Selectmenu(props) {
    const { value, onChange, options, isDisabled, isMulti, isSearchable, placeholder, id, label, mandatory, position, event } = props;

    const CustomStyle = {
        option: (base, state) => ({
            ...base,
            color: '#495281',
            fontSize: '12px',
            fontWeight: '500',
            ':first-child': {
                color: event ? '#0070fd' : '#495281'
            }
        })
    }

    return (
        <>
            <div className={classNames({ 'select-menu': true, 'select-menu--disabled': isDisabled })}>
                {label && <label>{label} {mandatory && <sup>*</sup>}</label>}
                <Select classNamePrefix="select-child" styles={CustomStyle} id={id} value={value} onChange={(e) => e ? onChange(e) : onChange({ label: '', value: '' })} options={options} isDisabled={isDisabled} isMulti={isMulti} isSearchable={isSearchable} placeholder={placeholder ? placeholder : 'Select'} maxMenuHeight={250} menuPlacement={position} isClearable={false} />
            </div>
        </>
    )
}

Selectmenu.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    mandatory: PropTypes.bool,
}

Selectmenu.defaultProps = {
    id: "",
    value: '',
    isDisabled: false,
    onChange: () => { },
    isMulti: false,
    isSearchable: false,
    placeholder: '',
    label: '',
    mandatory: false,
}

