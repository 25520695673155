import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default function Button(props) {

    const { xsmall, small, medium, compact, full, primary, secondary, transparent, ghost, right, disabled, _id, _src, label, handleOnSubmit } = props;

    return (
        <>
            <button className={classNames({ 'button': true, 'button--xsmall': xsmall, 'button--small': small, 'button--medium': medium, 'button--compact': compact, 'button--full': full, 'button--primary': primary, 'button--secondary': secondary, 'button--transparent': transparent, 'button--ghost': ghost, 'button--right': right, 'button--disabled': disabled })} id={_id} disabled={disabled} onClick={() => handleOnSubmit()}>
                {_src && <div className="button__image"><img src={_src} alt={_src} /></div>}{label}</button>
        </>
    )
}

Button.propTypes = {
    xsmall: PropTypes.bool,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    compact: PropTypes.bool,
    full: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    transparent: PropTypes.bool,
    ghost: PropTypes.bool,
    right: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    _id: PropTypes.string,
    _src: PropTypes.string
}

Button.defaultProps = {
    xsmall: false,
    small: false,
    medium: false,
    compact: false,
    full: false,
    primary: false,
    secondary: false,
    transparent: false,
    ghost: false,
    right: false,
    disabled: false,
    label: '',
    _id: '',
    _src: ''
}

