import { Images } from "assets/Images";


export const groups = [
    { id: "4f91292f-648d-466f-8b26-2d8721418c41", name: "055", path: "/_CLIENT/YOMA/055" },
    { id: "41f9dc53-ed7d-4077-b51f-a8da3656b386", name: "074", path: "/_CLIENT/YOMA/074" },
    { id: "1483e25e-fce9-4c91-8009-99023dc7ee8a", name: "077", path: "/_CLIENT/YOMA/077" },
    { id: "a7d6053e-52f7-477f-ae0d-96ca481e5c2c", name: "102", path: "/_CLIENT/YOMA/102" },
    { id: "d1280d31-2404-4870-b0c2-bf25b00fe75d", name: "AL", path: "/_CLIENT/CRED001/AL" },
    { id: "9a17866f-5273-44be-9ad5-dfb4ad34474d", name: "BMWT1", path: "/_CLIENT/BMW/BMWT1" },
    { id: "d22f85f5-1c2a-4f58-a2b4-99aeab5fb6d3", name: "CCT", path: "/_DEPT/CRO/ZRO/SRO/CCT" },
    { id: "899fccea-9163-4de0-9104-1c628bdace32", name: "CHN12345" },
    { id: "7c0be9a9-16e3-40d8-b5d6-8a6ea895c9cd", name: "CLT", path: "/_CLIENT/CLT" },
    { id: "688b04cb-6c3a-4f51-adff-5de3f42cc417", name: "CRED001", path: "/_CLIENT/CRED001" },
    { id: "6dd700bf-e3dc-4012-b59a-d02d8702f077", name: "DemoBank", path: "/_CLIENT/DemoBank" },
    { id: "d021dd29-d4fd-4fe5-a67b-e30951cdac8c", name: "HL", path: "/_CLIENT/BOA0807/HL" },
    { id: "1b911c06-b975-41aa-b5f9-30126ba642c4", name: "HL", path: "/_CLIENT/SUN123/HL" },
    { id: "7c19cecc-18b9-4d5a-b874-7cd54110ecba", name: "HL", path: "/_CLIENT/NF001/HL" },
    { id: "d8e3c959-cf22-4956-b4c7-a8e038eabaa9", name: "HL", path: "/_CLIENT/CRED001/HL" },
    { id: "dd52ce37-7c52-4c1c-85be-f38cc044b97c", name: "HL", path: "/_CLIENT/WES123/HL" },
    { id: "f90e4247-3da5-484c-95c3-f15ff04f71cf", name: "Karnataka" },
    { id: "f9017090-7be3-4edd-8a9f-28af7b437957", name: "LAT", path: "/_DEPT/CRO/ZRO/SRO/LAT" },
    { id: "fcfcfa55-435d-4a2f-9001-ea3db126bbdd", name: "PL", path: "/_CLIENT/PPL01/PL" },
    { id: "418e7c6b-267f-427d-8bcc-8228730ed748", name: "PL", path: "/_CLIENT/CRED001/PL" },
    { id: "febbc323-4f6d-460d-bfa6-0a964f762c45", name: "PL", path: "/_CLIENT/BOA0807/PL" },
    { id: "dec2dc49-1cf7-47b8-b73d-9292bedf7f8f", name: "PL", path: "/_CLIENT/NF001/PL" },
    { id: "41df983f-16bd-49d3-87c0-494f364ec2a8", name: "PPL01", path: "/_CLIENT/PPL01" }
];

export const tableConfig = {
    // Build our expander column
    id: 'expander', // Make sure it has an ID
    Cell: ({ row }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        row.canExpand ? (
            <span
                {...row.getToggleRowExpandedProps({
                    style: {
                        // We can even use the row.depth property
                        // and paddingLeft to indicate the depth
                        // of the row
                        paddingLeft: `${row.depth * 2}rem`,
                    },
                })}
            >
                {row.isExpanded ? <span><img src={Images.up} alt="<" /></span>
                    : <span><img src={Images.down} alt=">" /></span>}
            </span>
        ) : null,
};

export const dataObject = [
    {
        "code": "ABCD",
        "subRows": [
            {
                "takenBy": "Ram",
                "workedAccounts": 2,
                "contactableAccounts": 1,
            },
            {
                "takenBy": "krish",
                "workedAccounts": 2,
                "contactableAccounts": 2,
            }
        ],
        "workedAccounts": 4,
        "contactableAccounts": 3,
    },
    {
        "code": "XYZ",
        "subRows": [
            {
                "takenBy": "Jack",
                "workedAccounts": 5,
                "contactableAccounts": 0,
            },
            {
                "takenBy": "krish",
                "workedAccounts": 4,
                "contactableAccounts": 4,
            }
        ],
        "workedAccounts": 9,
        "contactableAccounts": 4,
    },
]

export const headerObject = [
    { label: 'Code', field_key: 'code' },
    { label: 'Worked Accounts', field_key: 'workedAccounts' },
    { label: 'Contactable Accounts', field_key: 'contactableAccounts' },
    { label: 'Taken By', field_key: 'takenBy' },
];

export const result = [
    {
        "Code": "ABCD",
        "Taken By": "",
        "Worked Accounts": 4,
        "Contactable Accounts": 3,
    },
    {
        "Code": "ABCD",
        "Taken By": "Ram",
        "Worked Accounts": 2,
        "Contactable Accounts": 1,
    },
    {
        "Code": "ABCD",
        "Taken By": "krish",
        "Worked Accounts": 2,
        "Contactable Accounts": 2,
    },
    {
        "Code": "XYZ",
        "Taken By": "",
        "Worked Accounts": 9,
        "Contactable Accounts": 4,
    },
    {
        "Code": "XYZ",
        "Taken By": "Jack",
        "Worked Accounts": 5,
        "Contactable Accounts": 0,
    },
    {
        "Code": "XYZ",
        "Taken By": "krish",
        "Worked Accounts": 4,
        "Contactable Accounts": 4,
    }
]

export const result1 = [
    {
        "Code": "ABCD",
        "Taken By": "",
        "Worked Accounts": 4,
        "Contactable Accounts": 3,
    },
    {
        "Taken By": "Ram",
        "Worked Accounts": 2,
        "Contactable Accounts": 1,
    },
    {
        "Taken By": "krish",
        "Worked Accounts": 2,
        "Contactable Accounts": 2,
    },
    {
        "Code": "XYZ",
        "Taken By": "",
        "Worked Accounts": 9,
        "Contactable Accounts": 4,
    },
    {
        "Taken By": "Jack",
        "Worked Accounts": 5,
        "Contactable Accounts": 0,
    },
    {
        "Taken By": "krish",
        "Worked Accounts": 4,
        "Contactable Accounts": 4,
    }
]