import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import PropTypes from 'prop-types';

import './style.scss';

export default function DatePickersRange(props) {
    const { value, onChange } = props;

    return (
        <>
            <DateRangePicker onChange={onChange} value={value} clearIcon={null} calendarIcon={null} format="dd-MM-y" dayPlaceholder=
                'DD' monthPlaceholder='MM' yearPlaceholder='YYYY' rangeDivider="to" />
        </>
    )
}

DatePickersRange.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func
}

DatePickersRange.defaultProps = {
    value: '',
    onChange: () => { }
}
