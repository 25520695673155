export const headers = [
    "",
    "tp_name",
    "total_actioned",
    "total_rpc",
    "total_ptp",
    "ptp/arr_amount",
    "total_arrangement",
    "total_cbl",
    "total_mdd",
    "total_nrpc",
    "total_onHold",
    "total_letter",
    "total_call",
    "total_exception",
];

export const innerHeaders = [
    "",
    "amtp_takenBy",
    "total_actioned",
    "total_rpc",
    "total_ptp",
    "ptp/arr_amount",
    "total_arrangement",
    "total_cbl",
    "total_mdd",
    "total_nrpc",
    "total_onHold",
    "total_letter",
    "total_call",
    "total_exception",
];

export const headerWithValue = [
    "total_actioned",
    "total_rpc",
    "total_ptp",
    "ptp/arr_amount",
    "total_arrangement",
    "total_cbl",
    "total_mdd",
    "total_nrpc",
    "total_onHold",
    "total_letter",
    "total_call",
    "total_exception",
];

export const mapHeader = {
    tp_name: "Campaign Name",
    amtp_takenBy: "Agent Name",
    total_actioned: "Acc Actioned",
    total_rpc: "RPC",
    total_ptp: "PTP",
    "ptp/arr_amount": "PTP/ARR Amount",
    total_arrangement: "Arrangement",
    total_cbl: "CBL",
    total_mdd: "MDD",
    total_nrpc: "NRPC",
    total_onHold: "OH",
    total_letter: "Letter",
    total_call: "Call",
    total_exception: "Exceptions",
};

export const dropDown = [
    "ptp/arr_amount",
    "total_actioned",
    "total_rpc",
    "total_ptp",
    "total_arrangement",
    "total_cbl",
    "total_mdd",
    "total_nrpc",
    "total_onHold",
    "total_letter",
    "total_call",
    "total_exception",
];

export const bgColors = [
    "#85B3D1FF",
    "#2460A7FF",
    "#B3C7D6FF",
    "#92B1B6",
    "#F1C5AE",
    "#BFD1DF",
    "#ECDDD0",
    "red",
    "green",
    "pink",
    "purple",
    "grey",
    "orange",
    "violet",
    "darkgrey",
    "lightgreen",
    "indigo",
    "brown",
];
