import { Images } from 'assets/Images';
import './styles.scss';

export default function NoRecord(props) {
    const { header, description } = props
    return (
        <>
            <div className="no-data">
                <div className="no-data__content">
                    <img src={Images.noRecord} alt="no-data" />
                    <h2>{header ? header : 'No data found!'}</h2>
                    <p>{description}</p>
                </div>
            </div>
        </>
    );
}