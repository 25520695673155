import { useState } from 'react';
import classNames from 'classnames';

import { useTable, useExpanded } from 'react-table'
import * as moment from 'moment';

import './table-style.scss';


export default function UITable(props) {
    const { userColumns, data } = props;

    const [sortObj, setSortObj] = useState({ key: '', type: '' });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { expanded },
    } = useTable(
        {
            columns: userColumns,
            data,
        },
        useExpanded // Use the useExpanded plugin hook
    )

    function amountFormat(value, index) {
        // [YOMA] - (EPIK-YB-125) - (2 Decimal Places for amount Fields) - (02-08-2022)
        return !isNaN(value) ? (parseFloat(value?.toFixed(2))?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : index > 2 ? 0 : '';
    }

    // [YOMA] - (EPIK-YB-125) - (2 Decimal Places for amount Fields) - (02-08-2022)
    function percentFormat(value) {
        return value === '0' ? value : Math.round(parseFloat(value));
    }

    function sortByfn(id, value) {
        setSortObj({ id, value });
    }

    function removeUnderscore(data) {
        return titleCase(data?.replace(/_/g, ' '));
    }

    // REG ISSUE - Title Case  Added Issue[29-06-2022]
    function titleCase(str) {
        return str?.toLowerCase().split(' ').map(function (word) {
            return (word?.charAt(0).toUpperCase() + word?.slice(1));
        }).join(' ');
    }

    return (
        <>
            <div className='hr-scroll'>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}
                                        {/* {column?.sortBy && <div className='sort-arrow' >
                                            <span className={classNames({ 'sort-arrow__up': true, 'is-active': sortObj.id === column.id && sortObj.value === 1 })} onClick={() => sortByfn(column.id, 1)}></span>
                                            <span className={classNames({ 'sort-arrow__down': true, 'is-active': sortObj.id === column.id && sortObj.value === -1 })} onClick={() => sortByfn(column.id, -1)}></span>
                                        </div>} */}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className={row.depth === 1 ? 'active' : ''}>
                                    {row.cells.map((cell, index) => {
                                        // [YOMA] - (EPIK-YB-125) - (2 Decimal Places for amount Fields) - (02-08-2022)
                                        return <td {...cell.getCellProps()}>{(cell.column.data_type === 'number' && !['Level 1', 'Level 2', 'Level 3'].includes(cell.column.summary_level) && cell?.column?.isAmount === 'Y') ? amountFormat(cell.value, index) : cell.column.data_type === 'date' ? (cell.value ? moment(cell.value).format('DD-MM-YYYY') : '') : (cell.column.data_type === 'number' && cell?.column?.isPercentage === 'Y') ? percentFormat(cell.value) : (typeof cell?.value === 'string' && cell?.value?.toString().includes('_')) ? removeUnderscore(cell.value) : cell.render('Cell')}
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}