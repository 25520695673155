import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as moment from 'moment';
import Button from '../button';
import DatePickersRange from '../datepicker-range';
import Selectmenu from '../select-menu';
import Textbox from '../textbox';
import { map, isEmpty, find } from 'lodash';

import './style.scss';

// let filterObj = {};
let currentRangeObj;
let range = { from: '', to: '' };

export default function UiFilter(props) {
    const { tableHeader, handleSubmit, defaultFilter } = props;
    const [date, setDate] = useState();
    const [filter, setFilter] = useState({});

    useEffect(() => {
        const arr = defaultFilter.map(e => ({ [e.id]: e.value }));
        const obj = Object.assign({}, ...arr);
        const res = tableHeader.reduce(
            (finalObj, iteratedObj) => ({
                ...finalObj,
                [iteratedObj.field_key]: '',
            }),
            {}
        );
        setFilter({ ...res, ...obj });
    }, [tableHeader, defaultFilter]);


    const handleDatepicker = (e, key) => {
        setDate(e);
        if (key === 'fromDate' || key === 'toDate' && e) {
            setFilter(state => ({ ...state, [key]: moment(e).format('YYYY/MM/DD') }));
            return;
        }
        if (!isEmpty(e)) {
            const date = { from: moment(e[0]).format('YYYY/MM/DD'), to: moment(e[1]).format('YYYY/MM/DD') };
            setFilter(state => ({ ...state, [key]: date }));
        } else {
            setFilter(state => ({ ...state, [key]: '' }));
            return false;
        }
    }

    const handleRangeValue = (e, type, key) => {
        // REG ISSUE - Data Type Issue[29-06-2022]
        // [YOMA] - (EPIK-YB-173) - (Reports Filter Region as Number Issue) - (08-08-2022)
        range = (type === 'from') ? { from: e?.target?.value !== '' ? +e?.target?.value : e?.target?.value, to: range?.to } :
            { from: range?.from, to: e?.target?.value !== '' ? +e?.target?.value : e?.target?.value };
        setFilter(state => ({ ...state, [key]: range }));
    }

    const handleTextInput = (e, key) => {
        setFilter(state => ({ ...state, [key]: e.target.value }));
    }

    const handleDropdown = (e, key) => {
        setFilter(state => ({ ...state, [key]: e.id }));
    }

    const displayFilteredData = (key, id) => {
        const keys = Object.keys(filter);
        // [YOMA] - (EPIK-YB-173) - (Reports Filter Region as Number Issue) - (10-08-2022)
        return (keys?.includes(key) && (filter[key][id] === 0 || filter[key][id])) ? filter[key][id] : '';
    }

    const displayInputData = (key) => {
        return filter[key];
    }

    const list = map(tableHeader, (heading, index) => {
        return (
            <>
                {
                    heading.filterBy && <li key={index}>
                        <span>{heading.label}{heading?.mandatory && <sup>*</sup>}</span>
                        {heading.data_type === 'date' && <DatePickersRange onChange={(e) => handleDatepicker(e, heading?.field_key)} clearIcon={null} calendarIcon={null} format="dd-MM-y" dayPlaceholder=
                            'DD' monthPlaceholder='MM' yearPlaceholder='YYYY' rangeDivider="to" value={[filter[heading?.field_key]?.from, filter[heading?.field_key]?.to]} />}

                        {heading.data_type === 'dropdown' && <Selectmenu label='Data Type' options={heading.dropDownValues} mandatory name="fa_allowedDataType" onChange={(e) => handleDropdown(e, heading.field_key)} />}

                        {heading.data_type === 'number' && <div className="number-field">
                            <Textbox placeholder='From Eg. 0' type="number" value={displayFilteredData(heading?.field_key, 'from')} onChange={(e) => handleRangeValue(e, 'from', heading.field_key)} />
                            <Textbox placeholder='To Eg. 100' type="number" onChange={(e) => handleRangeValue(e, 'to', heading.field_key)} value={displayFilteredData(heading?.field_key, 'to')} />
                        </div>}

                        {heading.data_type === 'string' &&
                            <Textbox placeholder={'Enter ' + heading.label} type="text" onChange={(e) => handleTextInput(e, heading.field_key)} value={displayInputData(heading.field_key)} />}
                    </li>
                }
            </>
        )
    })

    return (
        <>
            <div className="filter-wrap">
                <ul>{list}</ul>
                <div className='clear-button'>
                    <Button label="Apply" xsmall primary handleOnSubmit={() => handleSubmit('save', filter)} />
                    {/* REG ISSUE - resetting Values [29-06-2022] */}
                    <Button label="Clear" xsmall ghost primary handleOnSubmit={() => {
                        range = { from: '', to: '' }
                        delete filter.am_region
                        handleSubmit('back', filter)
                    }} />                </div>
            </div>
        </>
    )

}