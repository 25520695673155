import React, { useState } from "react";
import classes from "./Chart.module.css";
import { Bar, Pie } from "react-chartjs-2";
import { bgColors } from "../../../config/chart-data";

const UIChart = (props) => {
  const { summary, chartDropdown, barKey, level1, level2 } = props;
  const innerSelectList = summary.map((ele) => ele[level1]);
  const [innerSelect, setInnerSelect] = useState('');
  const [selectedHeader, setSelectedHeader] = useState(barKey);

  let barData = { labels: [], datasets: [] };
  let polarData = { labels: [], datasets: [] };

  summary.forEach((ele) => barData.labels.push(ele[level1]));
  let value = [];
  summary.forEach((ele) => value.push(ele[selectedHeader || barKey]));
  barData.datasets.push({
    label: getLabelByID(selectedHeader || barKey),
    data: value,
    backgroundColor: bgColors,
  });

  const target = innerSelect || innerSelectList[0];
  summary.forEach((ele) => {
    if (ele[level1] === target) {
      ele.subRows.forEach((child) =>
        polarData.labels.push(child[level2])
      );
      let childValue = [];
      ele.subRows.forEach((ele) => childValue.push(ele[selectedHeader]));
      polarData.datasets.push({
        label: getLabelByID(selectedHeader),
        data: childValue,
        backgroundColor: bgColors,
      });
    }
  });

  const handleOuterSelect = (event) => {
    setSelectedHeader(event.target.value);
    polarData = {};
  };

  const handleInnerSelect = (event) => {
    setInnerSelect(event.target.value);
  };

  function getLabelByID(id) {
    const val = chartDropdown.find(el => el.key === id);
    return val && val.value;
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <select className={classes.selectBox} onChange={handleOuterSelect}>
          {chartDropdown.map((el, index) => (
            <option key={index} value={el.key} className={classes.options}>
              {el.value}
            </option>
          ))}
        </select>
        <div className={classes.chart1}>
          <Bar data={barData} />
        </div>
        {innerSelectList[0] && (
          <div>
            <select
              className={classes.selectBox}
              onChange={handleInnerSelect}
            >
              {innerSelectList.map((header, index) => (
                <option
                  key={index}
                  value={header}
                  className={classes.options}
                >
                  {header}
                </option>
              ))}
            </select>
            <div className={classes.chart2}>
              <Pie data={polarData} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default UIChart;
