import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CollectionSummary from "../components/pages/collection-summary";

function ConditionalRouter() {

    return <>
        <Route exact path="/" component={CollectionSummary} />
    </>
}

function Routers() {
    return (
        <>
            <Router>
                <Switch>
                    <ConditionalRouter />
                </Switch>
            </Router>
        </>
    )
}

export default Routers
