import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

export default function Textbox(props) {
    const { id, type, value, placeholder, onChange, disabled, readonly, required, mandatory, maxlength, minlength, label, last, error, name, errorMessage } = props;
    return (
        <>
            <div className="textbox-row">
                {label && <label>{label} {mandatory && <sup>*</sup>}</label>}
                <input className={classNames({'textbox-row__input': true, 'textbox-row--error': error })} id={id} type={type} value={value} name={name} placeholder={placeholder} disabled={disabled} readOnly={readonly} required={required} maxLength={maxlength} minLength={minlength} last={last} onChange={onChange} autoComplete="off" />
                {error && <div className="textbox-row__error">{errorMessage}</div>}
            </div>
        </>
    )
}

Textbox.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    mandatory: PropTypes.bool,
    minlength: PropTypes.number,
    maxlength: PropTypes.number,
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    last: PropTypes.bool,
    error: PropTypes.bool,
    name: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func
}

Textbox.defaultProps = {
    id: '',
    type: '',
    // value: '',
    last: null,
    readonly: false,
    error: null,
    label: '',
    mandatory: false,
    placeholder: '',
    disabled: false,
    maxlength: 180,
    minlength: 180,
    name: '',

    required: false,
    onChange: () => { }
}