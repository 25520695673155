import { useQuery } from 'react-query';
import { useEffect, useState, useRef } from 'react';
import CsvDownload from 'react-json-to-csv'
import classNames from 'classnames';
import { map, filter, uniqBy, uniq, flatten, isObject, reduce, orderBy } from 'lodash';
import * as api from 'actions';
import { UITable, UIChart, UiFilter, Button, Loader, NoRecord } from 'components/generic';
import './style.scss';
import { Images } from 'assets/Images';
import { groups, tableConfig } from 'config';
import moment from 'moment';

let reportId;
let dateObj;
let filterData;
let level1;
let level2;
let level3;
let reportReqObj = { 'ruleCondition': [], 'filter': [], 'sort': [], 'timezone': 'Asia/Kolkata', 'download': 'N', 'preview': 'N', 'record_count': 10000, 'start_index': 0 };

export default function CollectionSummary() {
    const [reportList, setReportList] = useState([]);
    const [selectedReport, setSelectedReport] = useState({});
    const [count, setCount] = useState(0);
    const [showReportList, setShowReportList] = useState(false);
    const [tableHeader, setTableHeader] = useState([]);
    const [reportHeader, setReportHeader] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [chartDropdown, setChatDropdown] = useState([]);
    // [YOMA] - (Reports Summary DR Formula Change) - [14-07-2022]
    // [YOMA] - (ID-EPIK-YB-037) - (Litigation Summary report percentage field formula change) - (20-07-2022)
    const [percentObject, setPercentObject] = useState([]);

    let [entity, setEntity] = useState();
    let [product, setProduct] = useState();
    let [team, setTeam] = useState();
    let [branch, setBranch] = useState();
    const [showChart, setShowChart] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [filterObj, setFilterObj] = useState([]);
    const [downloadData, setDownloadData] = useState([]);

    const ref = useRef();
    useOnClickOutside(ref, () => {
        setShowReportList(false);
    });

    useEffect(() => {
        getEntityProductTeams();
    }, [])

    function formHeaderObject(obj) {
        setTableHeader(obj);
        level1 = filter(obj, el => el.summary_level === 'Level 1')[0]?.field_key;
        level2 = filter(obj, el => el.summary_level === 'Level 2')[0]?.field_key;
        level3 = filter(obj, el => el.summary_level === 'Level 3')[0]?.field_key;
        const header = filter(obj, el => el.field_key === level1 || el.field_key === level3 || el.field_key === level2 || el.data_type === 'number');
        header.map(el => { el.Header = el.label; el.accessor = el.field_key });
        header.unshift(tableConfig);
        setReportHeader(header);
    }

    // [YOMA] - (Reports Summary DR Formula Change) - [14-07-2022]
    // [YOMA] - (ID-EPIK-YB-037) - (Litigation Summary report percentage field formula change) - (20-07-2022)
    function formPercentObject(obj) {
        setPercentObject(map(filter(obj, el => Object.keys(el).includes('percentageFields') && el),
            elm => ({ header: elm?.field_key, value: elm?.percentageFields })));
    }


    function formReportData(data) {
        const lvl1 = Object.values(data?.reduce((acc, curr) => {
            const { [level1]: id, [level2]: key, ...rest } = curr;
            acc[id] = acc[id] || { [level1]: id, subRows: [] };
            acc[id].subRows.push(curr);
            Object.entries(rest).forEach(([k, v]) => {
                if (k !== 'am_accountNo') {
                    acc[id][k] = acc[id][k] || 0
                    if (v !== '' && !isNaN(v)) acc[id][k] += v;
                }
            });
            return acc
        }, {}))
        const lvl2 = lvl1.map(el => Object.values(el.subRows?.reduce((acc, curr) => {
            const { [level1]: id, [level2]: key, ...rest } = curr;
            acc[key] = acc[key] || { [level1]: id, [level2]: key };
            Object.entries(rest).forEach(([k, v]) => {
                if (k !== 'am_accountNo') {
                    acc[key][k] = acc[key][k] || 0
                    if (v !== '' && !isNaN(v)) acc[key][k] += v;
                }
            });
            return acc
        }, {})))
        const lvl3 = Object.values(flatten(lvl2)?.reduce((acc, curr) => {
            const { [level1]: id, [level2]: key, ...rest } = curr;
            acc[id] = acc[id] || { [level1]: id, subRows: [] };
            acc[id].subRows.push(curr);
            Object.entries(rest).forEach(([k, v]) => {
                if (k !== 'am_accountNo') {
                    acc[id][k] = acc[id][k] || 0
                    if (v !== '') acc[id][k] += v
                }
            });
            return acc;
        }, {}))

        const lvl4 = filter(lvl3, el => filter(el?.subRows, elm => delete elm[level1]));

        // [YOMA] - (Reports Summary Percentage) - [9-07-2022]
        const percentLvl1 = map(lvl4, ele => {
            let obj = { ...ele };
            map(percentObject, el => {
                // [YOMA] - (Reports Summary Percentage Round off) - [11-07-2022]
                obj = ele?.hasOwnProperty(el?.header) ?
                    // [YOMA] - (Reports Summary DR Formula Change) - [14-07-2022]
                    (el?.header === 'delinquentPercentage' ? ({
                        ...obj, [el.header]:
                            Math.round(reduce(el?.value[0], (sum, e) => sum + obj[e], 0) / obj[el?.value[1]] * 100) || 0
                    }) :
                        ({ ...obj, [el.header]: Math.round((obj[el?.value[0]] / obj[el?.value[1]]) * 100) || 0 })) : obj;
            })
            return obj;
        })
        // [YOMA] - (Reports Summary Percentage) - [9-07-2022]
        let percentLvl2 = map(percentLvl1, item => {
            const object = { ...item }
            const a = map(item?.subRows, ele => {
                let obj = { ...ele };
                map(percentObject, el => {
                    // [YOMA] - (Reports Summary Percentage Round off) - [11-07-2022]
                    obj = ele?.hasOwnProperty(el?.header) ?
                        // [YOMA] - (Reports Summary DR Formula Change) - [14-07-2022]
                        (el?.header === 'delinquentPercentage' ? ({
                            ...obj, [el.header]:
                                Math.round(reduce(el?.value[0], (sum, e) => sum + obj[e], 0) / obj[el?.value[1]] * 100) || 0
                        }) : ({ ...obj, [el.header]: Math.round((obj[el?.value[0]] / obj[el?.value[1]]) * 100) || 0 })) : obj;
                })
                return obj;
            })
            object.subRows = [...a];
            return object;
        })

        let l3 = {};
        map(data, (el, i) => {
            l3[el[level1]] = l3[el[level1]] || {}
            l3[el[level1]][el[level2]] = l3[el[level1]][el[level2]] || {};
            let t1 = moment(el[level3]).format('MM-DD-YYYY');
            t1 = t1 === 'Invalid Date' ? el[level3] : t1;
            l3[el[level1]][el[level2]][t1] = l3[el[level1]][el[level2]][t1] || {}
            // const t2 = l3[el[level1]][el[level2]][t1]['subRows'];
            // l3[el[level1]][el[level2]][t1]['subRows'] = t2?.length > 0 ? t2 : [];
            Object.entries(el).forEach(([k, v]) => {
                if (k !== 'am_accountNo') {
                    l3[el[level1]][el[level2]][t1][k] = l3[el[level1]][el[level2]][t1][k] || 0
                    if (v !== '' && !isNaN(v)) l3[el[level1]][el[level2]][t1][k] += v;
                }
            });

            l3[el[level1]][el[level2]][t1][level1] = el[level1];
            l3[el[level1]][el[level2]][t1][level2] = el[level2];
            l3[el[level1]][el[level2]][t1][level3] = t1;
            // l3[el[level1]][el[level2]][t1]['subRows'].push(el);
        })

        level3 && (percentLvl2 = percentLvl2.map(el => {
            el.subRows = el?.subRows?.map(ele => {
                ele['subRows'] = Object.values(l3[el[level1]][ele[level2]]);
                return ele;
            })
            return el;
        }))

        setReportData(percentLvl2);
        formDownloadData(percentLvl2);
        formChartData();
    }

    // get entity from userData data
    function getEntityProductTeams() {
        const en = map(groups, el => el.path?.includes('_CLIENT') && el.path.split('/')[2]);
        const pr = map(groups, el => el.path?.includes('_CLIENT') && el.path.split('/')[3]);
        const br = map(groups, el => ['_HIERARCHY'].includes(el?.path) && el.path.split('/').pop());
        branch = br.filter(el => el).toString();
        setBranch(br.filter(el => el).toString());
        entity = uniq(en.filter(elm => elm)).toString();
        setEntity(uniq(en.filter(elm => elm)).toString());
        product = uniq(pr.filter(elm => elm)).toString();
        setProduct(uniq(pr.filter(elm => elm)).toString());
        team = groups.map(el => el.id);
        setTeam(groups.map(el => el.id));
    }

    function formChartData() {
        const chart = map(reportHeader, el => el.data_type === 'number' && ({ key: el.field_key, value: el.Header }));
        const chartData = filter(chart, el => el);
        setChatDropdown(chartData);
    }

    // get date
    function getDate(val) {
        const date = new Date();
        let dateFrom;
        const currentDate = date.getDate();
        const currentMonth = date.getMonth();
        const currentYear = date.getFullYear();
        if (isObject(val) && val?.from && val?.to) {
            return [new Date(val?.from), new Date(val?.to)];
        } else {
            const obj = {
                'today': () => { dateFrom = date; },
                'wtt': () => { dateFrom = new Date(currentYear, currentMonth, currentDate - 7); },
                'mtt': () => { dateFrom = new Date(currentYear, currentMonth - 1, currentDate); },
                'ytt': () => { dateFrom = new Date(currentYear - 1, currentMonth, currentDate); },
                'default': () => { dateFrom = new Date(currentYear, currentMonth - 3, currentDate); },
            };
            (obj[val] || obj['default'])();
            return [dateFrom, date];
        }
    }

    function formDownloadData(obj) {
        const data = [];
        // filter(obj, el => (el[level2] = '', (data.push(el)), filter(el.subRows, elm => (elm[level1] = el[level1], data.push(elm)))));
        filter(obj, el => (el[level2] = '', el[level3] = '', data.push(el), filter(el.subRows, elm => (elm[level1] = el[level1], elm[level3] = '', data.push(elm), filter(elm.subRows, ellm => (ellm[level2] = elm[level2], data.push(ellm)))))));
        const newArr = data.map(({ subRows, ...rest }) => rest);
        // [YOMA] - [EPIK - YB - 081] - (PR Month and PR Year remove from downloaded csv) - (27-07-2022)
        level1 = filter(tableHeader, el => el.summary_level === 'Level 1')[0]?.field_key;
        level2 = filter(tableHeader, el => el.summary_level === 'Level 2')[0]?.field_key;
        level3 = filter(tableHeader, el => el.summary_level === 'Level 3')[0]?.field_key;
        const header = filter(tableHeader, el => el.field_key === level1 || el.field_key === level3 || el.field_key === level2 || el.data_type === 'number');
        const headerMap = header.reduce((acc, { label, field_key }) => {
            acc[field_key] = label;
            return acc;
        }, {});

        const result = newArr.map((obj) => {
            return Object.fromEntries(Object.keys(obj).map((key) => [headerMap[key], obj[key]]));
        });

        const object = { [level1]: null, [level2]: null, [level3]: null };
        const headingMap = Object.assign(object, headerMap);
        let resultArr = result.map(obj => Object.fromEntries(Object.keys(headingMap).map((key, index) => {
            obj[headingMap[key]] = typeof (obj[headingMap[key]]) === 'number' && index != 0 && index != 1 ? amountFormat(obj[headingMap[key]]) : obj[headingMap[key]];
            return [headingMap[key], obj[headingMap[key]]];
        })));
        setDownloadData(resultArr);
    }


    const amountFormat = (value) => {
        return !value ? '0' : parseFloat(value?.toFixed(2))?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    // get reports data
    const { refetch: refetchReportList, isLoading: listLoading } = useQuery(['reportList', ''], () => api.post('list', ''), {
        refetchOnWindowFocus: false, enabled: true,
        onSuccess: (data) => {
            const res = !data?.data?.data?.length ? [] : data?.data?.data[0];
            if (res.result?.length > 0) {
                const summary = filter(res.result, el => (el?.isDeleted !== 'Y' && el.reportType.includes('SUMMARY_VIEW')));
                // [YOMA] - (EPIK-YB-093) - (Order Reports Dropdown) - (27-07-2022)
                const orderedList = orderBy(summary, 'reportName', ['asc']);
                reportId = orderedList[0]?.reportId;
                const firstReport = orderedList[0];
                setReportList(orderedList);
                setCount(orderedList.length);
                setSelectedReport(firstReport);
                refetchReportHeader();
            } else {
                setReportList([]);
                setCount(0);
                setSelectedReport();
            }
            setLoading(false)
        }
    });

    // get reports config
    const { refetch: refetchReportHeader, isLoading: headerLoading } = useQuery(['reportHeader', ''], () => api.get(`config/${reportId}`, ''), {
        refetchOnWindowFocus: false, enabled: false,
        onSuccess: (res) => {
            const header = !res?.data?.data?.length ? [] : res?.data?.data[0];
            if (header.rc_columnsList?.length > 0) {
                // [YOMA] - (Reports Summary DR Formula Change) - [14-07-2022]
                // [YOMA] - (ID-EPIK-YB-037) - (Litigation Summary report percentage field formula change) - (20-07-2022)
                formPercentObject(header?.rc_columnsList);
                formHeaderObject(header?.rc_columnsList);
                reportId = header.rc_reportId;
                reportReqObj.filter = header?.rc_defaultFilter?.map(el => ({ id: el.id, value: el.value })) || filterData;
                setFilterObj(reportReqObj.filter);
                const variable = uniqBy(header.rc_variables, 'id');
                variable.map(el => {
                    if (el.value.includes('{')) {
                        ['am_associatedBranch', 'am_associatedDealer'].includes(el.id) &&
                            reportReqObj.ruleCondition.push({ id: el.id, value: branch });
                        el.id === level1 && reportReqObj.ruleCondition.push({ id: el.id, value: entity });
                        el.id === level2 && reportReqObj.ruleCondition.push({ id: el.id, value: product });
                        el.id === 'amt_team' && reportReqObj.ruleCondition.push({ id: el.id, value: team });
                    } else {
                        reportReqObj.ruleCondition.push(el);
                    }
                });
                setLoading(true)
                refetchReportData();
            } else {
                setLoading(false);
            }
        }
    });

    const { refetch: refetchReportData, isLoading: dataLoading } = useQuery(['reportData', ''], () => { return api.post(`${reportId}`, reportReqObj) }, {
        refetchOnWindowFocus: false, enabled: false,
        onSuccess: (el) => {
            const res = !el?.data?.data?.length ? [] : el?.data?.data[0]?.result;
            formReportData(res);
            setLoading(false);
        }
    });

    const handleShowReportList = () => {
        setShowReportList(!showReportList);
    }

    const handleReportSelected = (e) => {
        setLoading(true)
        setSelectedReport(e);
        setShowReportList(!showReportList);
        setShowChart(false);
        setReportHeader([]);
        setReportData([]);
        setFilterObj([]);
        setShowFilter(false);
        reportId = e.reportId;
        refetchReportHeader();
    }

    const handleShowChart = () => {
        chartDropdown.length > 0 && reportData && setShowChart(!showChart);
    }

    const handleShowFilter = () => {
        setShowFilter(!showFilter);
    }

    const handleFilter = (state, obj) => {
        // REG ISSUE - API for state Back and code refactor [29-06-2022]
        if (state === 'back') {
            reportReqObj.filter = []
        }
        if (state === 'save') {
            filterData = obj;
            (obj?.am_region?.from === '' && obj?.am_region?.to === '') && (delete obj.am_region)
            Object.keys(obj)?.filter(ele => !obj[ele] && delete obj[ele]);
            const data = Object.entries(obj).map(el => (el[1] !== '') && ({ id: el[0], value: el[1] }));
            reportReqObj.filter = data.filter(elm => elm);
        }
        setFilterObj(reportReqObj.filter);
        setLoading(true);
        refetchReportData();
    }

    if (listLoading || headerLoading || dataLoading || loading) {
        return <Loader loading={listLoading || headerLoading || dataLoading || loading} />
    }

    function useOnClickOutside(ref, handler) {
        useEffect(
            () => {
                const listener = (event) => {
                    if (!ref.current || ref.current.contains(event.target)) {
                        return;
                    }
                    handler(event);
                };
                document.addEventListener('mousedown', listener);
            },
            [ref, handler])
    }

    const droplist = map(reportList, (el, i) => {
        return <li key={i} onClick={() => handleReportSelected(el)} >{el.reportName}</li>
    });

    return (
        <>
            <div className='summary'>
                {selectedReport?.reportName && <div className="summary__title">
                    <h2>{selectedReport?.reportName}
                        <span>{count}</span>
                        <span className='drop-span' ref={ref}>
                            <img src={Images.dropdown} alt='' onClick={handleShowReportList} />
                            {showReportList && <ul className="scroll-bar">{droplist}</ul>}
                        </span>
                    </h2>
                    {/* {reportData.length > 0 && reportList.length > 0 && reportHeader.length > 0 && */}
                    {reportList?.length > 0 &&
                        <div className='filter'>
                            <Button compact transparent primary _src={Images.filter} label="Filter" handleOnSubmit={handleShowFilter} />
                            <CsvDownload className='download-button' data={downloadData} filename={`${selectedReport?.reportName}.csv`}>Download</CsvDownload>
                            <img className='filter__hamburger' src={Images.hamburger} alt='-' onClick={handleShowChart} />
                        </div>
                    }
                </div>}
                {showFilter && <UiFilter tableHeader={tableHeader} dateObj={dateObj} defaultFilter={filterObj} handleSubmit={handleFilter}></UiFilter>}
                {reportData?.length > 0 && reportList?.length > 0 && reportHeader?.length > 0 &&
                    <div className={classNames({ 'summary__table': !showChart, 'summary__chart': showChart })}>
                        <div className='table'>
                            {reportData && reportHeader && <UITable userColumns={reportHeader} data={reportData}></UITable>}
                        </div>
                        <div className='chart'>
                            {showChart && chartDropdown && reportData && <UIChart summary={reportData} chartDropdown={chartDropdown} barKey={chartDropdown[0]?.key} level1={level1} level2={level2} />}
                        </div>
                    </div>}
                {(!reportData?.length || !reportList?.length || !reportHeader?.length) && <NoRecord></NoRecord>}
            </div>
        </>
    )
}
