import axios from "axios";

const { REACT_APP_BASE_URL, REACT_APP_EID } = process.env;

const client = axios.create({ baseURL: REACT_APP_BASE_URL });

const token = '';

export const request = ({ ...options }) => {
    client.defaults.headers.common['e_id'] = REACT_APP_EID;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const widget = urlParams.get('widget')
    if (widget) {
        const auth_token = urlParams.get('auth_token')
        client.defaults.headers.common['token'] = auth_token
    } else {
        client.defaults.headers.common['token'] = token;
    }

    const onSuccess = response => response
    const onError = error => {
        return error.response
    }
    return client(options).then(onSuccess).catch(onError)
}

