import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default function Loader(props) {

    const { loading, label } = props;

    return (
        <>
            <div className={classNames({ 'loader': true, 'loader--active': loading })}>
                <div className="loader__spinner" /> {label && <span className="loader__label">{label}</span>}
            </div>
        </>
    )
}

Loader.propTypes = {
    loading: PropTypes.bool,
    label: PropTypes.string,
}

Loader.defaultProps = {
    loading: false,
    label: ''
}


